import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import DotLoader from "react-spinners/DotLoader";

import Footer from "../Footer";
import "./experience.css";

function Experiences() {
  const [experiences, setExp] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER_ENDPOINT + "/experience", {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((d) => {
        setExp(d);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  const renderExperiences = (experiences) => {
    return experiences.map((experience) => {
      return (
        <Card text="light" key={experience._id}>
          <Card.Body>
            <Container className="d-flex">
              <Row>
                <Col md={4} className="experienceContent">
                  <a href={experience.url} target="_blank" rel="noreferrer">
                    <img src={experience.logo} alt="company logo" />
                  </a>
                </Col>
                <Col md={8} className="experienceContent">
                  <Card.Title>{experience.title}</Card.Title>
                  <Card.Subtitle className="mb-2">
                    {experience.company}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    {experience.startDate} - {experience.endDate}
                  </Card.Subtitle>
                  <hr />
                  <ul>
                    {experience.jobDescription.map((job, index) => {
                      return <li key={index}>{job}</li>;
                    })}
                  </ul>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      );
    });
  };

  return (
    <div>
      <DotLoader
        color="#f0ece5"
        loading={isLoading}
        size={50}
        cssOverride={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      />
      {experiences && (
        <Container id="experiences" className="sections">
          <Row className="p-5">
            <h2 className="secondaryHeader">Experiences</h2>
            {renderExperiences(experiences)}
          </Row>
        </Container>
      )}

      <Footer />
    </div>
  );
}

export default Experiences;
