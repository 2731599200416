import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";

import { MdLaunch } from "react-icons/md";
import { FaGithub } from "react-icons/fa";

import { getIcons } from "../contact/utils/icons";
import "./project.css";

const ProjectModal = (props) => {
  return (
    <Modal
      show={props.showModal}
      project={props.project}
      onHide={props.handleCloseModal}
    >
      <Modal.Header data-bs-theme="dark" closeButton>
        <Modal.Title>{props.project.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Images */}
        {props.project.images.length > 1 ? (
          <Carousel indicators={false}>
            {props.project.images.map((image) => {
              return (
                <Carousel.Item>
                  <img
                    className="project-carousel-img"
                    src={image}
                    alt="screenshot of the project"
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        ) : null}

        {/* Description */}
        <p className="project-description">{props.project.description}</p>

        {/* Tags */}
        <Container className="tags">
          {props.project.tags.map((tag) => {
            return (
              <Row>
                <Col>
                  <p className="h6">{tag.category}</p>
                </Col>
                <Col>{tag.detail.map((detail) => getIcons(detail))}</Col>
              </Row>
            );
          })}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseModal}>
          Close
        </Button>
        <div style={{ "flex-grow": 1 }}></div>
        <div>
          {props.project.link.map((link) => {
            return link.name === "demo" ? (
              <Button href={link.url} target="_blank" variant="light">
                DEMO <MdLaunch className="icon" />
              </Button>
            ) : (
              <Button href={link.url} target="_blank" variant="secondary">
                GITHUB <FaGithub className="icon" />
              </Button>
            );
          })}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectModal;
