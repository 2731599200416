import { Card, Button } from "react-bootstrap";
import { MdLaunch } from "react-icons/md";
import { FaGithub } from "react-icons/fa";

function ProjectCard({ project, clickProject }) {
  return (
    <Card text="light" className="project-card">
      <div className="modal-box" onClick={() => clickProject(project)}>
        <Card.Img variant="top" src={project.thumbnail} className="cardImg" />
        <Card.Body>
          <Card.Title>{project.title}</Card.Title>
          <Card.Subtitle className="mb-2">{project.subTitle}</Card.Subtitle>
          <Card.Text>{project.description}</Card.Text>
        </Card.Body>
      </div>

      <div className="link-container">
        {project.link.map((link) => {
          return link.name === "demo" ? (
            <Button href={link.url} target="_blank" variant="light">
              DEMO <MdLaunch className="icon" />
            </Button>
          ) : (
            <Button href={link.url} target="_blank" variant="secondary">
              GITHUB <FaGithub className="icon" />
            </Button>
          );
        })}
      </div>
    </Card>
  );
}

export default ProjectCard;
