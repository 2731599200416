import {
  FaPython,
  FaJava,
  FaHtml5,
  FaCss3Alt,
  FaReact,
  FaBootstrap,
  FaNode,
} from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import {
  SiExpress,
  SiMysql,
  SiMongodb,
  SiMicrosoftsqlserver,
  SiOracle,
  SiPandas,
  SiSpring,
} from "react-icons/si";
import { BiLogoPostgresql, BiLogoSpringBoot } from "react-icons/bi";
import { GrHeroku } from "react-icons/gr";
import { VscCode } from "react-icons/vsc";

export const getIcons = (name) => {
  switch (name.toLowerCase().split(" ")[0]) {
    case "python":
      return <FaPython className="icon" title="Python" />;
    case "java":
      return <FaJava className="icon" title="Java" />;
    case "javascript":
      return <IoLogoJavascript className="icon" title="JavaScript" />;
    case "html":
      return <FaHtml5 className="icon" title="HTML" />;
    case "css":
      return <FaCss3Alt className="icon" title="CSS" />;
    case "react.js" || "react native" || "react":
      return <FaReact className="icon" title="React" />;
    case "node.js" || "node":
      return <FaNode className="icon" title="Node.js" />;
    case "express.js" || "express":
      return <SiExpress className="icon" title="Express.js" />;
    case "spring" || "spring boot":
      return <BiLogoSpringBoot className="icon" title="Spring" />;
    case "mysql":
      return <SiMysql className="icon" title="MySQL" />;
    case "mongodb":
      return <SiMongodb className="icon" title="MongoDB" />;
    case "sql server":
      return <SiMicrosoftsqlserver className="icon" title="SQL Server" />;
    case "oracle":
      return <SiOracle className="icon" title="Oracle" />;
    case "postgresql":
      return <BiLogoPostgresql className="icon" title="PostgreSQL" />;
    case "pandas":
      return <SiPandas className="icon" title="Pandas" />;
    case "heroku":
      return <GrHeroku className="icon" title="Heroku" />;
    case "bootstrap":
      return <FaBootstrap className="icon" title="Bootstrap" />;
    default:
      return <VscCode className="icon" title={name} />;
  }
};
